@import url("https://fonts.googleapis.com/css?family=Oswald:200,300,400,500,600,700");
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
    font-family: "Oswald", sans-serif;
    padding: 0;
    margin: 0;
    font-size: 15px;
    background-color: #f1f1f1;
}

a.meanmenu-reveal {
	display: none;
}
/* when under viewport size, .mean-container is added to body */
.mean-container .mean-bar {
	float: left;
	width: 100%;
	position: absolute;
	background: transparent;
	padding: 20px 0 0;
	z-index: 999;
	border-bottom: 1px solid rgba(0, 0, 0, 0.03);
	height: 55px;
}
.mean-container a.meanmenu-reveal {
	width: 35px;
	height: 30px;
	padding: 12px 15px 0 0;
	position: absolute;
	right: 0;
	cursor: pointer;
	color: #ffffff;
	text-decoration: none;
	font-size: 16px;
	text-indent: -9999em;
	line-height: 22px;
	font-size: 1px;
	display: block;
	font-weight: 700;
}
.mean-container a.meanmenu-reveal span {
	display: block;
	background: #ffffff;
	height: 4px;
    margin-top: 3px;
    border-radius: 3px;
}
.mean-container .mean-nav {
	float: left;
	width: 100%;
	background: #ffffff;
	margin-top: 55px;
}
.mean-container .mean-nav ul {
	padding: 0;
	margin: 0;
	width: 100%;
	border: none;
	list-style-type: none;
}
.mean-container .mean-nav ul li {
	position: relative;
	float: left;
	width: 100%;
}
.mean-container .mean-nav ul li a {
	display: block;
	float: left;
	width: 90%;
	padding: 1em 5%;
	margin: 0;
	text-align: left;
	color: #677294;
	border-top: 1px solid #DBEEFD;
	text-decoration: none;
}
.mean-container .mean-nav ul li a.active {
	color: #000000;
}
.mean-container .mean-nav ul li li a {
	width: 80%;
	padding: 1em 10%;
	color: #677294;
	border-top: 1px solid #DBEEFD;
	opacity: 1;
	filter: alpha(opacity=75);
	text-shadow: none !important;
	visibility: visible;
	text-transform: none;
	font-size: 14px;
}
.mean-container .mean-nav ul li.mean-last a {
	border-bottom: none;
	margin-bottom: 0;
}
.mean-container .mean-nav ul li li li a {
	width: 70%;
	padding: 1em 15%;
}
.mean-container .mean-nav ul li li li li a {
	width: 60%;
	padding: 1em 20%;
}
.mean-container .mean-nav ul li li li li li a {
	width: 50%;
	padding: 1em 25%;
}
.mean-container .mean-nav ul li a:hover {
	background: #252525;
	background: rgba(255,255,255,0.1);
}
.mean-container .mean-nav ul li a.mean-expand {
	margin-top: 3px;
	width: 100%;
	height: 24px;
	padding: 12px !important;
	text-align: right ;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 2;
	font-weight: 700;
	background: transparent;
	border: none !important;
}
.mean-container .mean-push {
	float: left;
	width: 100%;
	padding: 0;
	margin: 0;
	clear: both;
}
.mean-nav .wrapper {
	width: 100%;
	padding: 0;
	margin: 0;
}
/* Fix for box sizing on Foundation Framework etc. */
.mean-container .mean-bar, .mean-container .mean-bar * {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}
.mean-remove {
	display: none !important;
}
.mobile-nav {
    display: none;
}
.mobile-nav.mean-container .mean-nav ul li a.active {
    color: #FF2D55;
}
.main-nav {
    background: #000;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
    z-index: 999;
    height: auto;
}
.mean-nav .dropdown-toggle::after {
	display: none;
}
.navbar-light .navbar-brand, .navbar-light .navbar-brand:hover {
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
}
.main-nav nav ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}
.main-nav nav .navbar-nav .nav-item {
    position: relative;
    padding: 15px 0;
}
.main-nav nav .navbar-nav .nav-item a {
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    color: #ffffff;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 15px;
    margin-right: 15px;
}
.main-nav nav .navbar-nav .nav-item a:hover, 
.main-nav nav .navbar-nav .nav-item a:focus, 
.main-nav nav .navbar-nav .nav-item a.active {
    color: #FF2D55;
}
.main-nav nav .navbar-nav .nav-item:hover a {
    color: #FF2D55;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu {
    -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
            box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
    background: #0d1028;
    position: absolute;
    top: 80px;
    left: 0;
    width: 250px;
    z-index: 99;
    display: block;
    padding-top: 20px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 20px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li {
    position: relative;
    padding: 0;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a {
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    padding: 9px 15px;
    margin: 0;
    display: block;
    color: #ffffff;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a:hover, 
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a:focus, 
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a.active {
    color: #FF2D55;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
    position: absolute;
    left: -100%;
    top: 0;
    opacity: 0 !important;
    visibility: hidden !important;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
    opacity: 1 !important;
    visibility: visible !important;
    top: -20px !important;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
    position: absolute;
    left: -100%;
    top: 0;
    opacity: 0 !important;
    visibility: hidden !important;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu li:hover .dropdown-menu {
    opacity: 1 !important;
    visibility: visible !important;
    top: -20px !important;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
    color: #ffffff;
    text-transform: capitalize;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover, 
.main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus, 
.main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
    color: #FF2D55;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li:hover a {
    color: #FF2D55;
}
.main-nav nav .navbar-nav .nav-item:hover ul {
    opacity: 1;
    visibility: visible;
    top: 100%;
}
.main-nav nav .navbar-nav .nav-item:last-child .dropdown-menu {
    left: auto;
    right: 0;
}

/* Responsive Style */
@media only screen and (max-width: 991px) {
    .mobile-nav {
        display: block;
        position: relative;
    }
    .mobile-nav .logo {
		text-decoration: none;
        position: absolute;
        top: 11px;
        z-index: 999;
        left: 15px;
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 20px;
    }
    .mean-container .mean-bar {
        background-color: #000;
        padding: 0;
    }
    .mean-container a.meanmenu-reveal {
        padding: 15px 15px 0 0;
    }
    .mobile-nav nav .navbar-nav .nav-item a i {
        display: none;
    }
    .main-nav {
        display: none !important;
    }
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
:root {
  --baseColor: #656c7c;
  --baseSize: 16px;
  --baseLineHeight: 1.5;
  --fontFamily: Inter, sans-serif;
  --pink: #DB5E70;
  --pinkLight: #ffecf0;
  --blue: #481652;
  --redTick: url("data:image/svg+xml,%3Csvg width='18' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.552.134 5.717 10.97 1.448 6.701 0 8.149l5.717 5.717L18 1.583 16.552.134Z' fill='%23EA455F'/%3E%3C/svg%3E%0A");
  --whiteTick: url("data:image/svg+xml,%3Csvg width='18' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.552.134 5.717 10.97 1.448 6.701 0 8.149l5.717 5.717L18 1.583 16.552.134Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
  --close: url("data:image/svg+xml,%3Csvg width='18' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 1.414 16.586 0 9 7.586 1.414 0 0 1.414 7.586 9 0 16.586 1.414 18 9 10.414 16.586 18 18 16.586 10.414 9 18 1.414Z' fill='%23B1B8C9'/%3E%3C/svg%3E");
  --entpIcon: url("data:image/svg+xml,%3Csvg width='42' height='42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.813 11.077 21 1.155l17.187 9.922v19.846L21 40.845 3.813 30.923V11.077Z' stroke='%23fff' stroke-width='2'/%3E%3Ccircle cx='21' cy='21' r='8' stroke='%23fff' stroke-width='2'/%3E%3C/svg%3E");
  --triangle: url("data:image/svg+xml,%3Csvg width='42' height='42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.813 11.077 21 1.155l17.187 9.922v19.846L21 40.845 3.813 30.923V11.077Z' stroke='%23fff' stroke-width='2'/%3E%3Ccircle cx='21' cy='21' r='8' stroke='%23fff' stroke-width='2'/%3E%3C/svg%3E");

}

* {
  box-sizing: border-box;
}

html {
  color: var(--baseColor);
  font-family: var(--fontFamily);
  font-size: var(--baseSize);
  line-height: var(--baseLineHeight);
}


.plans {
  width: 94%;
  max-width: 1400px;
  margin: 0 auto;
}
.plans__container {
  padding: 1rem 0 2rem;
}

.plansHero {
  text-align: center;
  padding: 5rem 0 4.5rem;
  line-height: 1.21;
}
.plansHero__title {
  font-weight: 700;
  font-size: 2rem;
  margin: 0 0 1rem 0;
  color: #000;
}
.plansHero__subtitle {
  margin: 0;
}

.planItem {
  --border: 1px solid #e6e6e6;
  --bgColor: #fff;
  --boxShadow: none;
  background-color: var(--bgColor);
  border: var(--border);
  border-radius: 1rem;
  box-shadow: var(--boxShadow);
  padding: 2rem 1.5rem;
  display: inline-flex;
  flex-direction: column;
}
.planItem__container {
  --direction: column;
  display: grid;
  grid-auto-flow: var(--direction);
  grid-auto-columns: 1fr;
  gap: 1.5rem;
}
.planItem .price {
  --priceMargin: 2rem 0;
}
.planItem--pro {
  --border: 0;
  --boxShadow: 0px 14px 30px rgba(204, 204, 204, 0.32);
}
.planItem--pro .label {
  --labelBg: #fdb72e;
  --labelColor: #fff;
}
.planItem--entp {
  --bgColor: var(--blue);
}
.planItem--entp .card {
  --titleColor: #fff;
  --descColor: rgba(255, 255, 255, 0.8);
  background-color: #481652;
}
.planItem--entp .card__icon {
  background-image: var(--entpIcon);
  background-size: cover;
}
.planItem--entp .price,
.planItem--entp .featureList {
  --color: #fff;
}
.planItem--entp .featureList {
  --icon: var(--whiteTick);
}
.planItem .button {
  margin-top: auto;
}

.button {
  --bgColor: var(--pinkLight);
  --color: var(--pink);
  --shadowColor: rgb(234 76 137 / 30%);
  --outline: var(--pink);
  border-radius: 0.5rem;
  display: block;
  width: 100%;
  padding: 1rem 1.5rem;
  border: 0;
  line-height: inherit;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 600;
  background-color: var(--bgColor);
  color: var(--color);
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.button--pink {
  --bgColor: var(--pink);
  --color: #fff;
  --shadowColor: rgb(234 76 137 / 50%);
}
.button--white {
  --bgColor: #fff;
  --shadowColor: rgb(255 255 255 / 30%);
  --outline: #fff;
}
.button:hover {
  transform: translateY(-2px);
  box-shadow: 0px 6px 10px var(--shadowColor);
}
.button:focus-visible {
  outline-offset: 2px;
  outline: 2px solid var(--outline);
}

.card {
    --titleColor: #000;
    --descColor: var(--baseColor);
    border: none; /* Ensures no border is shown */
  }
  
.card__header {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.card__icon {
  width: 2.625rem;
  height: 2.625rem;
}
.card h2 {
  color: var(--titleColor);
  font-size: 1.5rem;
  line-height: 1.2;
  font-weight: 400;
  margin: 0;
  flex-grow: 1;
}
.card__desc {
  margin: 1.5rem 0 0;
  color: var(--descColor);
}

.label {
  --labelColor: var(--baseColor);
  --labelBg: #e5e5e5;
  font-weight: 600;
  line-height: 1.25;
  font-size: 1rem;
  text-align: center;
  padding: 0.625rem 1.125rem;
  border-radius: 2rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: var(--labelBg);
  color: var(--labelColor);
}

.price {
  --color: #000;
  --priceMargin: 0;
  display: flex;
  color: var(--color);
  align-items: center;
  gap: 0.5625rem;
  font-weight: 600;
  font-size: 2rem;
  margin: var(--priceMargin);
}
.price span {
  font-size: 1rem;
  font-weight: 400;
  color: var(--baseColor);
}

.featureList {
  --color: #000;
  --icon: var(--redTick);
  --height: 0.875rem;
  margin: 0 0 2.75rem;
  padding: 0;
  font-weight: 500;
}
.featureList li {
    color: var(--color);
    margin-bottom: 2rem;
    display: flex;
    align-items: flex-start; /* Change from 'center' to 'flex-start' */
    gap: 1rem;
  }
  
  .featureList li:before {
    content: "";
    background-image: var(--icon);
    background-size: cover;
    display: inline-block; /* Changed from block */
    flex-shrink: 0; /* Ensures icon does not resize */
    width: 1.125rem;
    height: var(--height);
    margin-top: 0.2rem; /* Add slight margin for alignment */
  }
  
.featureList li:last-child {
  margin-bottom: 0;
}
.featureList li.disabled {
  --color: #b1b8c9;
  --height: 1.15rem;
  --icon: var(--close);
}

.symbol {
  --big: 2.625rem;
  --small: 1.5rem;
  --radius: 0.25rem;
  border: 2px solid var(--blue);
  width: var(--big);
  height: var(--big);
  border-radius: var(--radius);
  position: relative;
}
.symbol--rounded {
  --radius: 2rem;
}
.symbol:after {
  content: "";
  box-sizing: border-box;
  display: block;
  position: absolute;
  border: 2px solid var(--pink);
  width: var(--small);
  height: var(--small);
  border-radius: var(--radius);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 640px) {
  .plans {
    max-width: 480px;
    width: 90%;
  }

  .planItem__container {
    --direction: row;
  }
}
@media screen and (min-width: 641px) and (max-width: 768px) {
  :root {
    --baseSize: 12px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1080px) {
  :root {
    --baseSize: 14px;
  }
}